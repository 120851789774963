import { ApiService } from './../services/api/api.service';
import { BehaviorSubject } from 'rxjs';
export class ImageStorage {
  imageArray: Array<ImagesClass>;
  emptyImage:ImagesClass;
  private url = '';
  constructor(url) {
    this.url = url;
    this.imageArray = [];
    this.emptyImage = new ImagesClass(0,false,'');
  }

  getImageFormDataBase(id) {

  }
  returnImage(id):ImagesClass {
    if(id == 'disabled'){
      return null;
    }

    if(id == 'null'){
      return this.emptyImage;
    }
    const imageFind = this.imageArray.find(image => {
      return image.id == id;
    })
    if (imageFind == null) {
      this.imageArray.push(new ImagesClass(id,true,this.url));
    } else {
      return imageFind;
    }
    return this.emptyImage;

  };
}

export class ImagesClass {
  id: number;
  image_name: string;
  image_size: number;
  patch: string;
  alt: string;
  ImageAsArrayCounter:number;
  uploader_id: number;
  create_time: Date;
  name: string;
  patch_id:number;
  title: string;
  src: string;
  url:string;
  patchArray:Array<string>;
  dataReady_:BehaviorSubject<boolean>;
  constructor(id: number,autoCompleate: boolean = false, url:string = "null") {
    this.dataReady_ = new BehaviorSubject(false);
    this.src = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
    this.alt = 'wait';
    this.title = 'wait';
    this.id = id;
    this.url = url;

    if(autoCompleate){
     this.loadDataFromDataBase();
    }
  }
  LoadAllData(id: number, image_name: string, image_size: number, patch: string,patch_id:number, uploader_id: number, create_time: Date, alt: string, title: string) {
    this.id = id;
    this.alt = alt;
    this.image_name = image_name;
    this.image_size = image_size;
    this.patch = patch;
    this.patch_id = patch_id;
    this.uploader_id = uploader_id;
    this.create_time = create_time;
    this.title = title;
  }
  Qsrc(quality){
    if(this.src =='data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='){
      return 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';
    }
   return this.src+"/"+quality
  }
  QsrcAsync(quality): Promise<string>{
    return new Promise<string>((resolve, reject) => {
      resolve(this.Qsrc(quality));
    });
  }
  loadDataFromDataBase(): void{
    ApiService.instance.short('', 'returnImageInformation/' + this.id).then((data: Array<ImagesClass>) => {
      data = data['data'];
      this.alt = data[0].alt;
      this.src = this.src + this.id;
      this.image_name = data[0].image_name;
      this.image_size = data[0].image_size;
      this.patch = data[0].patch;
      this.uploader_id = data[0].uploader_id;
      this.create_time = data[0].create_time;
      this.title =data[0].title;
      this.src = this.url+ this.id;
      this.dataReady_.next(true);
    }).catch(data => {
      console.log('Zdjęcie nie znalezione')
    })
  }


}
